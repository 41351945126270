<template>
  <div class="btn-group">
    <img
      v-if="imagen != null"
      class="dropdown-image"
      v-bind:src="require('@/assets/img/' + imagen + '')"
    />
    <input
      type="text"
      @click.stop="toggleMenu()"
      @blur="toggleMenuLater()"
      class="dropdown-toggle"
      v-bind:placeholder="placeholder"
      @keyup="filtrarOpciones"
      ref="dropdownInput"
      v-show="conInput"
      v-bind:style="imagen != null ? { 'padding-left': '50px' } : ''"
      autocomplete="no"
      :disabled="isDisabled == true"
    />
    <input
      type="button"
      style="text-align: left; cursor: pointer"
      @click.stop="toggleMenu()"
      @blur="toggleMenuLater()"
      class="dropdown-toggle"
      v-bind:placeholder="placeholder"
      @keyup="filtrarOpciones"
      ref="dropdownInput2"
      v-show="!conInput"
      :value="placeholderText"
      v-bind:style="imagen != null ? { 'padding-left': '50px' } : ''"
      autocomplete="no"
      :disabled="isDisabled == true"
    />
    <img class="dropdown-caret" src="../assets/img/icon/caret.png" />
    <ul
      class="dropdown-menu"
      v-bind:class="{ 'dropdown-menu-up': paraArriba }"
      v-show="showMenu"
    >
      <li v-for="option in opcionesFiltradas" :key="option.id" :id="option.id">
        <a href="javascript:void(0)" @click="updateOption(option)">{{
          option[getName]
        }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedOption: {
        name: "",
      },
      showMenu: false,
      opcionesFiltradas: null,
      placeholderText: "Please select an item",
    };
  },
  props: {
    options: {
      type: [Array, Object],
    },
    selected: {},
    placeholder: [String],
    usarSelected: [Boolean],
    paraArriba: [Boolean],
    name: [String],
    conInput: [Boolean],
    imagen: [String],
    isDisabled: [Boolean],
  },
  computed: {
    getName() {
      if (this.name != null) return this.name;
      else return "name";
    },
  },
  mounted() {
    if (this.usarSelected == true) {
      this.selectedOption = this.selected;
      if (this.conInput) {
        this.$refs.dropdownInput.value = this.selectedOption[this.getName];
      } else {
        this.placeholderText = this.selectedOption[this.getName];
      }
    }
    this.opcionesFiltradas = this.options;
    if (this.placeholder) {
      this.placeholderText = this.placeholder;
    }
  },
  created() {},
  methods: {
    updateOption(option) {
      this.selectedOption = option;
      if (this.conInput) {
        this.$refs.dropdownInput.value = this.selectedOption[this.getName];
      } else {
        this.placeholderText = this.selectedOption[this.getName];
      }
      this.$emit("updateOption", this.selectedOption);
    },

    toggleMenu() {
      this.showMenu = !this.showMenu;
      this.opcionesFiltradas = this.options;
      if (this.conInput) {
        this.$refs.dropdownInput.value = this.selectedOption[this.getName];
      } else {
        if (
          this.selectedOption[this.getName] != "" &&
          this.selectedOption[this.getName] != null
        ) {
          this.placeholderText = this.selectedOption[this.getName];
        } else {
          this.placeholderText = this.placeholder;
        }
      }
    },
    toggleMenuLater() {
      var that = this;
      setTimeout(function () {
        that.showMenu = false;
        that.opcionesFiltradas = this.options;
      }, 150);
    },
    filtrarOpciones(el) {
      this.opcionesFiltradas = [];
      var that = this;
      this.options.forEach(function (item) {
        if (
          item[that.getName]
            .toUpperCase()
            .indexOf(el.target.value.toUpperCase()) > -1
        )
          that.opcionesFiltradas.push(item);
      });
    },
    resetDropwdown() {
      this.selectedOption = {};
      this.$refs.dropdownInput.value = "";
      this.$refs.dropdownInput2.value = "";
      this.$emit("updateOption", this.selectedOption);
    },
  },
};
</script>

<style scoped lang="scss" src="@/assets/css/components/dropdown.scss"></style>
